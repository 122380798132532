/* -----------------------------------------------------------------------------
 *
 * Layouts: Container
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

/* -----------------------------------------------------------------------------
 * Container
 * ----------------------------------------------------------------------------- */

.container {
  @mixin clearfix;

  width: 100%;
  max-width: var( --grid-width );
  margin-right: auto;
  margin-left: auto;
  padding-right: var( --grid-gutter );
  padding-left: var( --grid-gutter );
}

/* -----------------------------------------------------------------------------
 * Weighted Options
 * ----------------------------------------------------------------------------- */

.weightedRight,
.weightedLeft {
  @media (--large) {
    display: flex;
  }
}

/* -----------------------------------------------------------------------------
 * Weighted Right
 * ----------------------------------------------------------------------------- */

.weightedRight {
  @media (--large) {
    display: flex;
  }

  & > *:first-child {
    @media (--large) {
      width: calc( 30% - ( var( --grid-gutter ) - ( var( --grid-gutter ) / 2 ) ) );
    }
  }

  & > *:last-child {
    margin-top: var( --spacer );

    @media (--large) {
      width: calc( 70% - ( var( --grid-gutter ) - ( var( --grid-gutter ) / 2 ) ) );
      margin-top: 0;
      margin-left: var( --grid-gutter );
    }
  }
}

/* -----------------------------------------------------------------------------
 * Weighted Left
 * ----------------------------------------------------------------------------- */

.weightedLeft {
  @media (--large) {
    display: flex;
  }

  & > *:first-child {
    @media (--large) {
      width: calc( 70% - ( var( --grid-gutter ) - ( var( --grid-gutter ) / 2 ) ) );
    }
  }

  & > *:last-child {
    margin-top: var( --spacer );

    @media (--large) {
      width: calc( 30% - ( var( --grid-gutter ) - ( var( --grid-gutter ) / 2 ) ) );
      margin-top: 0;
      margin-left: var( --grid-gutter );
    }
  }
}
