/* -----------------------------------------------------------------------------
 *
 * Buttons
 *
 * ----------------------------------------------------------------------------- */

.button {
  display: inline-block;
  height: auto;
  padding: 12px 32px;
  border: 3px solid var( --color-cyan-1 );
  border-radius: 25px;
  background-color: var( --color-black );
  box-shadow: none;
  color: var( --color-cyan-1 );
  font-size: rem( 18px );
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: lh( 16px, 20px );
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: normal;
  cursor: pointer;

  @media (--small) {
    font-size: rem( 24px );
  }

  @media (--medium) {
    width: auto;
    font-size: rem( 32px );
  }
}

.button:hover,
.button:focus {
  transition: all 0.3s ease;
  background-color: var( --color-cyan-1 );
  color: var( --color-black );
  text-decoration: none;
}

.button:hover:enabled,
.button:focus:enabled {
  transition: all 0.3s ease;
  background-color: var( --color-cyan-1 );
  color: var( --color-black );
  text-decoration: none;
}

.button[ disabled ],
.button.disabled {
  opacity: 0.5;
  color: var( --color-white );
  cursor: not-allowed;
}

.button + .button {
  margin-left: 16px;
}

/* -----------------------------------------------------------------------------
 * Modifier: Button Large
 * ----------------------------------------------------------------------------- */

.large {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* -----------------------------------------------------------------------------
 * Modifier: Button Medium
 * ----------------------------------------------------------------------------- */

.medium {
  padding: 16px 28px;
}

/* -----------------------------------------------------------------------------
 * Modifier: Button Small
 * ----------------------------------------------------------------------------- */

.small {
  padding-top: 4px;
  padding-bottom: 4px;
}

/* -----------------------------------------------------------------------------
 * Modifier: Primary
 * ----------------------------------------------------------------------------- */

.primary {
  border-color: var( --color-tertiary );
  background-color: var( --color-tertiary );
  color: var( --color-secondary );
}

.primary:hover:enabled,
.primary:focus:enabled {
  border-color: var( --color-secondary );
  background-color: var( --color-white );
  color: var( --color-secondary );
}

/* -----------------------------------------------------------------------------
 * Modifier: Secondary
 * ----------------------------------------------------------------------------- */

.secondary {
  border-color: var( --color-secondary );
  background-color: var( --color-white );
  color: var( --color-secondary );
}

.secondary:hover:enabled,
.secondary:focus:enabled {
  background-color: var( --color-secondary );
  color: var( --color-white );
}

/* -----------------------------------------------------------------------------
 * Block Buttons
 * ----------------------------------------------------------------------------- */

.block {
  display: block;
  width: 100%;

  & + .button {
    margin-top: 16px;
    margin-left: 0;
  }
}

/* -----------------------------------------------------------------------------
 * Modifier: Block Button XS Only
 * ----------------------------------------------------------------------------- */

.blockOnlyXS {
  @media (--small-max) {
    display: block;
    width: 100%;
  }
}

/* -----------------------------------------------------------------------------
 * Modifier: Block Button SM Only
 * ----------------------------------------------------------------------------- */

.blockOnlySM {
  @media (--small) and (--medium-max) {
    display: block;
    width: 100%;
  }
}

/* -----------------------------------------------------------------------------
 * Modifier: Block Button MD Only
 * ----------------------------------------------------------------------------- */

.blockOnlyMD {
  @media (--medium) and (--large-max) {
    display: block;
    width: 100%;
  }
}

/* -----------------------------------------------------------------------------
 * Modifier: Block Button LG Only
 * ----------------------------------------------------------------------------- */

.blockOnlyLG {
  @media (--large) and (--xlarge-max) {
    display: block;
    width: 100%;
  }
}

/* -----------------------------------------------------------------------------
 * Modifier: Block Button XL Only
 * ----------------------------------------------------------------------------- */

.blockOnlyXL {
  @media (--xlarge) {
    display: block;
    width: 100%;
  }
}
